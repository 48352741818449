.month-label{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.month-text{
    border-top-left-radius: 0.56381rem;
    border-top-right-radius: 0.56381rem;
    width: 6rem;
    color: #045139;
}
.table-container{
    border-radius: 0.56381rem;
}

.month-container{
    border-radius: 0.56381rem;
    background: #88E53E;
}
.table-row{
    border-bottom: 1.5px solid #045139;
}
 .tableBorder{
    border-right: 1px solid #045139;
 }
 .tableRow{
    font-size: 0.3rem;
 }