.student-header{
    color: #000;
    font-family: Inter;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}

.export-label{
    color: #2E72F5;
text-align: center;
font-family: Inter;
font-size: 0.8125rem;
font-style: normal;
font-weight: 600;
line-height: 1.125rem; /* 138.462% */
letter-spacing: 0.00813rem;
text-transform: capitalize;
}