.student-filter-container{
    border-radius: 0.25rem;
    background: #dce6fa;
    height: auto;
}
.text-field{
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.10);
    opacity: 0.4;
    background: rgba(0, 0, 0, 0.20);
}