.close-icon{
    position: absolute;
    right: 0;
    top: 0;
    background-color: white;
    color: black;
    font-size: 20px;
    text-align: center;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
}
.modal-container{
    height: 85vh;
    overflow: scroll;
}
.text-con{
    background-color: rgba(10, 6, 92, 0.05);
    border-radius: 0.8995rem;
    color: #045139;
    font-family: Maven Pro;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.69844rem; 
}