.close-icon{
    position: absolute;
    right: 0;
    top: 0;
    background-color: white;
    color: black;
    font-size: 20px;
    text-align: center;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
}
.modal-container{
    height: 85vh;
    overflow: scroll;
}
.text-list{
    background: rgba(10, 6, 92, 0.05);
    border-radius: 0.90206rem;
    color: #045139;
    font-weight: 600;
}
.left-box-text{
    color: "#045139";
    font-weight: 600;
}